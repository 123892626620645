<template>
    <div v-loading="loading" v-if="client" class="client">
        <el-tabs v-model="active_tab" ref="ClientTabs">
            <el-tab-pane label="Portfolio" name="portfolio">
                <div slot="label" class="client-tab-label"><svg-icon icon-class="portfolio" /><div>Portfolio</div></div>
                <keep-alive>
                    <Portfolio ref="Portfolio" :client="client" />
                </keep-alive>
            </el-tab-pane>
            <el-tab-pane label="Strategies" name="strategies">
                <div slot="label" class="client-tab-label"><svg-icon icon-class="strategies" /><div>Strategies</div></div>
                <keep-alive>
                    <Strategy ref="Strategy" :client="client" />
                </keep-alive>
            </el-tab-pane>
            <el-tab-pane label="Bucket" name="bucket">
                <div slot="label" class="client-tab-label"><svg-icon icon-class="bucket" /><div>Buckets Control</div></div>
                Buckets Control
            </el-tab-pane>
            <el-tab-pane label="Risk Report" name="risk">
                <div slot="label" class="client-tab-label"><svg-icon icon-class="risk_report" /><div>Risk Report</div></div>
                <keep-alive>
                    <RiskReport :client="client" />
                </keep-alive>
            </el-tab-pane>
            <el-tab-pane label="Profile" name="profile">
                <div slot="label" class="client-tab-label"><svg-icon icon-class="user" /><div>Profile</div></div>
                <keep-alive>
                    <Profile :client="client" />
                </keep-alive>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Portfolio from './Portfolio'
import Strategy from './Strategy'
import RiskReport from './RiskReport'
import Profile from './Profile'

export default {
    name: 'client',
    components:{
        Portfolio, 
        Strategy, 
        RiskReport, 
        Profile, 
    },

    props: {
        clientid: {
            required: true,
        },
    },

    data(){
        return {
            loading: false,
            client: undefined,
            active_tab: 'portfolio'
        }
    },

    methods: {
        get_portfolios(){
            this.$refs.Portfolio.refresh()
        }
    },

    mounted(){
        if (this.clientid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$emit('tab-title-change', this.client.first_name + ' ' + this.client.last_name)
                let $this = this    
                this.$nextTick().then(function () {
                    $this.$store.dispatch('currency/getList');
                })
            })
        }
    },
}
</script>
