<template>
    <Widgets type="risk-report" :clientid="client._id" no-client-change />
</template>

<script>
import Widgets from "@/components/Widgets"

export default {
    name: 'risk-report',
    components: { Widgets },

    props: {
        client: {
            required: true,
        },
    },
}
</script>
